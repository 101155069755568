import React, { Component } from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import Cart from "../../Cart/Cart"
import Utils from "../../../utils/node.utils"
import EnglishLogo from "../../../images/countries/uk-min.png"
import SpanishLogo from "../../../images/countries/spain-min.png"
import DutchLogo from "../../../images/countries/netherlands-min.png"

const languages = {
  en: {
    small_text: "EN",
    text: "English",
    logo: EnglishLogo,
    path: "",
    code: "en",
  },
  es: {
    small_text: "ES",
    text: "Español",
    logo: SpanishLogo,
    path: "es",
    code: "es",
  },
  nl: {
    small_text: "NL",
    text: "Nederlands",
    logo: DutchLogo,
    path: "nl",
    code: "nl",
  },
}

export default class TopBar extends Component {
  state = {
    ts_value: "",
    languageBarOpen: false,
    language: "en",
    css: "dropdown-menu dropdown-menu-right",
  }

  languageHandler = () => {
    const { languageBarOpen } = this.state

    languageBarOpen
      ? this.setState({
          languageBarOpen: false,
          css: "dropdown-menu dropdown-menu-right",
        })
      : this.setState({
          languageBarOpen: true,
          css: "dropdown-menu dropdown-menu-right show",
        })
  }

  eventListener(e) {
    if (e.key === "F3" || ((e.ctrlKey || e.metaKey) && e.key === "f")) {
      e.preventDefault()
      this.text.focus()
    }
  }

  componentDidMount() {
    const { language, commonContentMap } = this.props

    if (language) {
      this.setState({ language: language })
    }
    this.timeoutID = Utils.DisplayNewsletterNotification(commonContentMap)
    if (window) {
      window.addEventListener("keydown", this.eventListener)
    }
  }

  componentWillUnmount() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID)
    }
    if (window) {
      window.removeEventListener("keydown", this.eventListener)
    }
  }

  getLanguage = () => {
    const { language } = this.state
    return languages[language]
  }

  getOtherLanguages = () => {
    const { language } = this.state
    let menuItems = []

    for (let key in languages) {
      if (key !== language) {
        let obj = languages[key]
        let currentPage = "/"

        if (this.props.originPage !== "") {
          currentPage = "/" + this.props.originPage
        }

        if (obj.path !== "") {
          currentPage = "/" + obj.path + currentPage
        }

        menuItems.push(
          <Link
            key={obj.small_text}
            to={currentPage}
            title={obj.text}
            state={{ lang: obj.code }}
            className="dropdown-item text-sm"
          >
            <img
              src={obj.logo}
              alt={obj.text}
              title={obj.text}
              className="topbar-flag"
            />
            {obj.text}
          </Link>
        )
      }
    }
    return menuItems
  }

  handleChange = event => {
    this.setState({
      ts_value: event.target.value,
    })
  }

  onSubmit = event => {
    event.preventDefault()
    event.target.children[0].blur()
    this.onSearch()
  }

  onSearchClick = event => {
    event.preventDefault()
    this.onSearch()
  }

  onSearch = () => {
    const { ts_value } = this.state
    this.setState({
      ts_value: "",
    })

    Utils.DoGoogleTagEvent({
      dataLayer: {
        event: "search",
        search: {
          value: ts_value,
        },
      },
    })

    navigate(this.getLink("/shop?ts=" + ts_value), {
      state: { ts_value: ts_value },
    })
  }

  getLink = link => {
    if (this.props.language) {
      return "/" + this.props.language + link
    }
    return link
  }

  render() {
    const { originPage } = this.props
    const { ts_value, languageBarOpen, css } = this.state

    let language = this.getLanguage()
    let otherLanguages = this.getOtherLanguages()
    const commonContentMap = this.props.commonContentMap

    return (
      <div className="top-bar">
        <div className="container-fluid">
          <div className="row d-flex align-items-center">
            <div className="col-3 col-lg-1 topbar-nowrap">
              <a
                href={commonContentMap.banner_link_instagram.value}
                title="Instagram"
                className="text-muted text-hover-dark"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                href={commonContentMap.banner_link_facebook.value}
                title="Facebook"
                className="text-muted text-hover-dark ml-2"
              >
                <i className="fab fa-facebook" />
              </a>
              <a
                href={commonContentMap.banner_link_pinterest.value}
                title="Pinterest"
                className="text-muted text-hover-dark ml-2"
              >
                <i className="fab fa-pinterest" />
              </a>
            </div>
            <div className="col-6 border-left d-lg-block d-none topbar-nowrap">
              {commonContentMap.banner_top.value}
            </div>
            <div className="col-9 col-lg-5 d-flex justify-content-end topbar-nowrap">
              <form className="" onSubmit={this.onSubmit}>
                <input
                  className="form-control searchbox-height"
                  type="text"
                  id="text"
                  ref="text"
                  value={ts_value}
                  onChange={this.handleChange}
                  placeholder={commonContentMap.banner_search.value}
                  aria-label="Search box"
                />
              </form>
              <div className="ml-2 topbar-nowrap">
                <a
                  href="#"
                  title={commonContentMap.banner_search.value}
                  className="text-muted text-hover-dark text-uppercase text-right"
                  onClick={this.onSearchClick}
                  aria-label="Search"
                >
                  <i className="fa fa-search" />
                </a>
              </div>
              <div
                className="ml-2 topbar-nowrap"
                id="wishlist-div"
                key="wishlist-div"
              >
                <Cart
                  name="wishlist"
                  title={commonContentMap.wishlist_name.value}
                  icon="fa fa-heart"
                  link={this.getLink("/wishlist")}
                  storageKey="wishlist-items"
                  originPage={originPage}
                  isWishlist={true}
                />
              </div>
              <div className="ml-2 topbar-nowrap" id="cart-div" key="cart-div">
                <Cart
                  name="cart"
                  title={commonContentMap.cart_name.value}
                  icon="fa fa-shopping-cart"
                  link={this.getLink("/cart")}
                  storageKey="cart-items"
                  originPage={originPage}
                />
              </div>
              <div className="dropdown ml-2 topbar-nowrap">
                <a
                  id="langsDropdown"
                  href="#"
                  title={commonContentMap.banner_language.value}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={languageBarOpen}
                  className="dropdown-toggle topbar-link"
                  onClick={this.languageHandler}
                >
                  <img
                    src={language.logo}
                    alt={language.text}
                    title={language.text}
                    className="topbar-flag"
                  />
                </a>
                <div aria-labelledby="langsDropdown" className={css}>
                  {otherLanguages}
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col mt-2 d-none d-block d-md-block d-lg-none topbar-nowrap text-center">
              {commonContentMap.banner_top.value}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
