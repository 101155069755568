import React, { Component } from "react"
import Menu from "./Menu"
import TopBar from "./Topbar"
import Logo from "./Logo"
import Utils from "../../../utils/node.utils"
import { store as tStore } from "../../Store/Store"

export default class Header extends Component {
  state = {
    bannerCss: "container-fluid",
    orderCss: "container-fluid d-none",
  }

  componentDidMount() {
    if (this.props.originPage === "order") {
      return
    }

    const checkoutLink = Utils.OrderInProgress()

    if (checkoutLink) {
      this.unsubscribe = tStore.subscribe(this.storeUpdate)
      this.storeUpdate()
      return
    }
  }

  getOrder() {
    const { t } = this.state
    if (this.loaded) {
      return
    }
    if (t) {
      Utils.OrderInProgress(t, this.updateOrderInProgress)
      this.loaded = true
    }
  }

  componentWillUnmount() {
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const { t } = this.state
    const storeState = tStore.getState()

    if (storeState.t && storeState.t !== t) {
      this.setState(
        {
          t: storeState.t,
        },
        this.getOrder
      )
    }
  }

  updateOrderInProgress = order => {
    switch (order.status) {
      case "open":
        this.setState({
          bannerCss: "container-fluid d-none",
          orderCss: "container-fluid",
          checkoutLink: order.checkout_link,
        })
        break
      case "paid":
        localStorage.removeItem("ss_o")
        break
    }
  }

  onClick = event => {
    event.preventDefault()
    window.location.replace(this.state.checkoutLink)
  }

  render() {
    const {
      props: { commonContentMap, origin, originPage, language },
      state: { bannerCss, orderCss, checkoutLink },
    } = this
    return (
      <>
        <header className="header">
          <TopBar
            origin={origin}
            language={language}
            originPage={originPage}
            commonContentMap={commonContentMap}
          />
          <div className={bannerCss}>
            <div className="row topbar-banner">
              <div className="col-12 mb-1 mt-1 text-white text-center text-uppercase">
                {commonContentMap.banner_text.value}
              </div>
            </div>
          </div>
          <div className={orderCss}>
            <div className="row topbar-banner-order">
              <a
                href={checkoutLink}
                title={commonContentMap.banner_text_payment_pending.value}
                className="col-12 mb-1 mt-1 text-white text-center text-uppercase"
              >
                {commonContentMap.banner_text_payment_pending.value}
              </a>
            </div>
          </div>
          <Logo language={language} commonContentMap={commonContentMap} />
          <Menu
            language={language}
            originPage={originPage}
            links={[
              "menu_home",
              "menu_shop",
              "menu_jewelry",
              "menu_accessories",
              "menu_blog",
              "menu_about",
              "menu_contact",
            ]}
            commonContentMap={commonContentMap}
          />
        </header>

        <div className="header-margin" />
      </>
    )
  }
}
