import React, { Component } from "react"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import Utils from "../../../utils/node.utils"

export default class Menu extends Component {
  state = {
    navbarOpen: false,
    css: "collapse navbar-collapse ",
  }

  // componentDidMount() {
  //   const existingNavbar = JSON.parse(localStorage.getItem("navbar-open"))
  //   if (existingNavbar) {
  //     if (existingNavbar.navbarOpen) {
  //       this.navbarHandler()
  //     }
  //   }
  // }

  navbarHandler = () => {
    // localStorage.setItem(
    //   "navbar-open",
    //   JSON.stringify({
    //     navbarOpen: !this.state.navbarOpen,
    //   })
    // )

    this.state.navbarOpen
      ? this.setState({ navbarOpen: false, css: "collapse navbar-collapse" })
      : this.setState({
          navbarOpen: true,
          css: "collapse navbar-collapse show",
        })
  }

  getHighlightedCss = link => {
    const { originPage, language } = this.props
    const selfLink = Utils.GetLanguageLink(language, "/" + originPage)
    if (link === selfLink) {
      return "nav-link text-uppercase active"
    }
    return "nav-link text-uppercase"
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-sticky navbar-airy navbar-light bg-white bg-fixed-white">
        <div className="container-fluid">
          <div
            role="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler ml-auto"
            onClick={this.navbarHandler}
          >
            <FaBars />
          </div>

          <div id="navbarCollapse" className={this.state.css}>
            <ul className="navbar-nav mx-auto text-center">
              {this.props.links.map(link => {
                const singleContentLink = Utils.GetSingleContentLink(
                  this.props.commonContentMap[link]
                )

                return (
                  <li key={link} className="nav-item">
                    <Link
                      to={singleContentLink.link}
                      title={singleContentLink.value}
                      className={this.getHighlightedCss(singleContentLink.link)}
                    >
                      {singleContentLink.value}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
