import React, { Component } from "react"
import { Link } from "gatsby"
import Utils from "../../../utils/node.utils"
import Newsletter from "../../Shared/Newsletter/Newsletter"

export default class Footer extends Component {
  getLink = page => {
    if (this.props.language) {
      return "/" + this.props.language + page
    }
    return page
  }

  render() {
    const { commonContentMap, language } = this.props
    const aboutLink = Utils.GetSingleContentLink(commonContentMap.menu_about)
    const contactLink = Utils.GetSingleContentLink(
      commonContentMap.menu_contact
    )
    const termsLink = Utils.GetSingleContentLink(commonContentMap.menu_terms)
    const privacyLink = Utils.GetSingleContentLink(
      commonContentMap.menu_privacy
    )
    const cookiesLink = Utils.GetSingleContentLink(
      commonContentMap.menu_cookies
    )
    const faqLink = Utils.GetSingleContentLink(commonContentMap.menu_faq)
    const shippingLink = Utils.GetSingleContentLink(
      commonContentMap.menu_shipping
    )
    const guaranteeLink = Utils.GetSingleContentLink(
      commonContentMap.menu_guarantee
    )

    return (
      <div className="py-6 bg-pink text-muted">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3 mb-5 mb-lg-0">
              <h6 className="font-weight-bold text-uppercase text-lg text-dark mb-3">
                {commonContentMap.text_follow_us.value}
              </h6>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    href={commonContentMap.banner_link_instagram.value}
                    title="Instagram"
                    className="text-muted text-hover-dark text-xl"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={commonContentMap.banner_link_facebook.value}
                    title="Facebook"
                    className="text-muted text-hover-dark text-xl"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={commonContentMap.banner_link_pinterest.value}
                    title="Pinterest"
                    className="text-muted text-hover-dark text-xl"
                  >
                    <i className="fab fa-pinterest" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="d-block d-lg-none col-12 col-lg-4 mb-5 mb-lg-0">
              <h6 className="font-weight-bold text-uppercase text-lg text-dark mb-3">
                {commonContentMap.text_keep_in_touch.value}
              </h6>
              <ul className="list-unstyled">
                <li>
                  <Newsletter
                    commonContentMap={commonContentMap}
                    prefix="mobile_"
                    language={language}
                  />
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 mb-5 mb-lg-0">
              <h6 className="text-uppercase text-dark mb-3">
                {commonContentMap.text_company.value}
              </h6>
              <ul className="list-unstyled">
                <li>
                  <Link
                    to={aboutLink.link}
                    title={aboutLink.value}
                    className="text-muted"
                  >
                    {aboutLink.value}
                  </Link>
                </li>
                <li>
                  <Link
                    to={termsLink.link}
                    title={termsLink.value}
                    className="text-muted"
                  >
                    {termsLink.value}
                  </Link>
                </li>
                <li>
                  <Link
                    to={privacyLink.link}
                    title={privacyLink.value}
                    className="text-muted"
                  >
                    {privacyLink.value}
                  </Link>
                </li>
                <li>
                  <Link
                    to={cookiesLink.link}
                    title={cookiesLink.value}
                    className="text-muted"
                  >
                    {cookiesLink.value}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6 mb-5 mb-lg-0">
              <h6 className="text-uppercase text-dark mb-3">
                {commonContentMap.text_customer_service.value}
              </h6>
              <ul className="list-unstyled">
                <li>
                  <Link
                    to={contactLink.link}
                    title={contactLink.value}
                    className="text-muted"
                  >
                    {contactLink.value}
                  </Link>
                </li>
                <li>
                  <Link
                    to={faqLink.link}
                    title={faqLink.value}
                    className="text-muted"
                  >
                    {faqLink.value}
                  </Link>
                </li>
                <li>
                  <Link
                    to={shippingLink.link}
                    title={shippingLink.value}
                    className="text-muted"
                  >
                    {shippingLink.value}
                  </Link>
                </li>
                <li>
                  <Link
                    to={guaranteeLink.link}
                    title={guaranteeLink.value}
                    className="text-muted"
                  >
                    {guaranteeLink.value}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="d-none d-lg-block col-lg-4">
              <h6 className="font-weight-bold text-uppercase text-lg text-dark mb-3">
                {commonContentMap.text_keep_in_touch.value}
              </h6>
              <ul className="list-unstyled">
                <li>
                  <Newsletter
                    commonContentMap={commonContentMap}
                    prefix="desktop_"
                    language={language}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
