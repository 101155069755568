import React, { Component } from "react"
import SBFLogo from "../../../images/logo/logo-min.png"
import { Link } from "gatsby"
import Utils from "../../../utils/node.utils"

export default class Logo extends Component {
  render() {
    const { commonContentMap } = this.props
    const link = Utils.GetSingleContentLink(commonContentMap.menu_home)
    return (
      <div className="mt-3 container">
        <div className="row d-flex align-items-center">
          <div className="container">
            <Link to={link.link} title={"Lotta Mae"} className="row">
              <div className="col-1 col-md-3"></div>
              <div className="col-10 col-md-6 ar-54-11">
                <img
                  src={SBFLogo}
                  alt="Lotta Mae"
                  title={"Lotta Mae"}
                  className="img-fluid"
                />
              </div>
              <div className="col-1 col-md-3"></div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
