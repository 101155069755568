import React, { Component } from "react"
import { Link } from "gatsby"
import Utils from "../../utils/node.utils"
import { store as tStore, setCart, setWishlist } from "../Store/Store"

export default class Cart extends Component {
  state = {
    css: "text-hover-dark text-muted",
    length: "",
  }

  componentDidMount() {
    const { originPage, name } = this.props

    if (originPage == name) {
      this.setState({
        css: "text-hover-dark text-dark",
      })
    }
    this.unsubscribe = tStore.subscribe(this.storeUpdate)
    this.setCartLength()
  }

  componentWillUnmount() {
    Utils.Unsubscribe(this.unsubscribe)
  }

  storeUpdate = () => {
    const { isWishlist } = this.props
    const storeState = tStore.getState()

    if (storeState.updatedCart && !isWishlist) {
      this.setCartLength()
      tStore.dispatch(setCart(false))
    }
    if (storeState.updatedWishlist && isWishlist) {
      this.setCartLength()
      tStore.dispatch(setWishlist(false))
    }
  }

  setCartLength() {
    const { storageKey } = this.props
    const cart = Utils.GetCart(storageKey)
    let length = 0
    for (let key in cart) {
      length += cart[key].quantity
    }
    this.setState({
      length: length === 0 ? "" : "(" + length + ")",
    })
    return
  }

  render() {
    const { title, icon, link } = this.props
    const { length, css } = this.state
    return (
      <Link to={link} title={title} className={css} aria-label={link}>
        <i className={icon} alt={title}>
          {length}
        </i>
      </Link>
    )
  }
}
