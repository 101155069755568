import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"
import { StaticQuery, graphql } from "gatsby"
import Cookies from "universal-cookie"

function createPathLink(selfLink) {
  return selfLink === "" ? selfLink : "/" + selfLink
}

function SEO({
  description,
  language,
  meta,
  keywords,
  title,
  image,
  selfLink,
}) {
  if (!image) {
    image = "https://lottamae.com/logo-full/logo.png"
  }

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        let url = data.site.siteMetadata.siteUrl
        let lang
        let hrefLang1
        let hrefLang2

        if (!selfLink || selfLink === "/") {
          selfLink = ""
        }

        switch (language) {
          case "en":
            lang = "en"
            hrefLang1 = {
              rel: "alternate",
              hreflang: "es",
              href: url + "/es" + createPathLink(selfLink),
            }
            hrefLang2 = {
              rel: "alternate",
              hreflang: "nl",
              href: url + "/nl" + createPathLink(selfLink),
            }
            break
          case "es":
            lang = "es"
            hrefLang1 = {
              rel: "alternate",
              hreflang: "en",
              href: url + createPathLink(selfLink),
            }
            hrefLang2 = {
              rel: "alternate",
              hreflang: "nl",
              href: url + "/nl" + createPathLink(selfLink),
            }
            url = url + "/es"
            break
          case "nl":
            lang = "nl"
            hrefLang1 = {
              rel: "alternate",
              hreflang: "en",
              href: url + createPathLink(selfLink),
            }
            hrefLang2 = {
              rel: "alternate",
              hreflang: "es",
              href: url + "/es" + createPathLink(selfLink),
            }
            url = url + "/nl"
            break
        }

        url = url + createPathLink(selfLink)
        const canonical = {
          rel: "canonical",
          href: url,
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            link={[canonical, hrefLang1, hrefLang2]}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:image`,
                content: image,
              },
              {
                property: `og:image:alt`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:locale`,
                content: language,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  language: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  language: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
