import React from "react"
import PropTypes from "prop-types"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import CookieConsent from "react-cookie-consent"
import Cookies from "universal-cookie"
import Utils from "../../../utils/node.utils"
import { navigate } from "gatsby"

const Layout = input => {
  return (
    <>
      <Header
        origin={input.origin}
        language={input.language}
        searchPage={input.searchPage}
        originPage={input.originPage}
        commonContentMap={input.commonContentMap}
      />
      {input.children}
      <Footer
        language={input.language}
        commonContentMap={input.commonContentMap}
      />

      <CookieConsent
        location="bottom"
        buttonText={input.commonContentMap.cookie_banner_accept.value}
        declineButtonText={input.commonContentMap.cookie_banner_settings.value}
        enableDeclineButton={true}
        cookieName="sbf-gdpr"
        onAccept={onAcceptCookie}
        onDecline={() => {
          onDeclineCookie(input.language)
        }}
        cookieSecurity={true}
        disableStyles={true}
        style={{
          background: "rgba(0,0,0,0.75)",
          alignItems: "center",
          left: "10px",
          right: "10px",
          bottom: "0px",
          position: "fixed",
          zIndex: 2,
        }}
        buttonStyle={{ background: "white", color: "black", width: "50%" }}
        declineButtonStyle={{
          background: "black",
          color: "white",
          width: "50%",
        }}
        disableButtonStyles={true}
        buttonClasses="btn"
        declineButtonClasses="btn"
        containerClasses="row"
        contentClasses="text-white col-12 col-lg-8 mt-4 mb-2 mb-lg-4"
        buttonWrapperClasses="col-12 col-lg-4 mt-4 mb-4"
      >
        {Utils.GetMarkdown(input.commonContentMap.cookie_banner_text.value, {
          link: "text-white text-decoration-underline",
          paragraph: "text-justify",
        })}
      </CookieConsent>
    </>
  )
}

function onAcceptCookie() {
  const cookies = new Cookies()
  cookies.set("sbf-gdpr-analytical", "true", { path: "/" })
  cookies.set("sbf-gdpr", "true", { path: "/" })
}

function onDeclineCookie(language) {
  const cookies = new Cookies()

  // remove cookie automatically set by component
  cookies.remove("sbf-gdpr", { path: "/" })
  navigate(Utils.GetLanguageLink(language, "/cookies#cookie-consent"))
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
